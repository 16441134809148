<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb
      :items="items"
      :search_route="`/categories/add/${id}`"
      search_title="إضافه قسم فرعي"
      icon="fa-plus"
    />
    <MainLoader v-if="loading"></MainLoader>
    <div class="fadeIn" v-else>
      <!-- End Breadcrumb -->

      <!-- Start Main Section -->

      <main>
        <v-data-table
          class="elevation-1 thumb diff_table"
          :headers="headers"
          :items="rows"
          :search="search"
          :loading="loading"
          :expanded.sync="expanded"
          :loading-text="$t('table.loadingData')"
          item-key="id"
          :items-per-page="paginations.items_per_page"
          hide-default-footer
        >
          <!-- ================== You Can use any slots you want ================== -->
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <span
              class="status"
              :class="item.is_active ? 'success' : 'canceled'"
            >
              {{ item.is_active ? $t("active") : $t("inactive") }}
            </span>
          </template>
          <!-- Start:: Users Routes -->
          <template v-slot:[`item.image`]="{ item }">
            <img
              :src="item.image"
              width="60"
              height="60"
              class="rounded-circle"
              alt=""
              @click="show_model_1"
            />
          </template>

          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length" class="expandDate fadeIn">
              <span class="expandTitle">{{ $t("titles.moreDetails") }}</span>
              <ul>
                <li v-for="(value, key) of expandData" :key="key">
                  <span class="key">{{ key }}</span>
                  <span class="value">
                    <template v-if="value != null && value != 'null'">
                      {{ value }}
                    </template>
                    <template v-else-if="value == null || value == 'null'">
                      {{ $t("table.noData") }}
                    </template>
                  </span>
                </li>
              </ul>
            </td>
          </template>
          <template v-slot:[`item.extendIcon`]="{ item }">
            <div class="_actions">
              <v-icon class="show" small @click="clickRow(item)">
                fas fa-angle-double-down
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.desc`]="{ item }">
            <span :title="item.desc" v-if="item.desc">
              {{ item.desc.slice(0, 50) }}
              {{ item.desc.length > 50 ? "..." : "" }}
            </span>
            <span v-else> ---- </span>
          </template>

          <template v-slot:[`item.ordering`]="{ item }">
            <span>{{ item.ordering }}</span>
          </template>
          <template v-slot:[`item.products_count`]="{ item }">
            <span>{{ item.products_count }} {{ $t("titles.product") }}</span>
          </template>

          <template v-slot:[`item.parent`]="{ item }">
            <router-link
              v-if="item.parent.parent"
              :to="'/categories/' + item.parent.parent.id + '/sub-category'"
              class="main-router"
            >
              {{ item.parent.name }}
            </router-link>

            <router-link
              v-else
              :to="'/categories/' + item.parent.id + '/sub-category'"
              class="main-router"
            >
              {{ item.parent.name }}
            </router-link>
          </template>
          <template v-slot:[`item.count_of_children`]="{ item }">
            <router-link
              v-if="item.count_of_children"
              :to="'/categories/' + item.id + '/sub-category'"
              class="main-router"
            >
              {{ item.count_of_children }} {{ $t("titles.section") }}
              <i class="fas fa-link mr-1"></i>
            </router-link>
            <span v-else>
              {{ item.count_of_children }}
            </span>
          </template>

          <!-- Start:: Report Reason Modal Button -->
          <template v-slot:[`item.report_reason`]="{ item }">
            <v-icon class="show" small v-if="item.report_reason">
              fal fa-eye
            </v-icon>

            <span v-else>_</span>
          </template>
          <!-- End:: Report Reason Modal Button -->

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions d-flex">
              <v-icon class="show" small @click="editItem(item)">
                fal fa-edit
              </v-icon>

              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <div class="d-flex justify-content-between">
              <h3 class="table-title title">
                {{ $t("titles.subSections") }} ({{ statisticsItem }})
              </h3>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    {{ $t("table.deletedialog.areYouSure") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm">
                      {{ $t("table.deletedialog.ok") }}
                    </v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">
                      {{ $t("table.deletedialog.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div class="px-5 pt-3 frm-action d-flex flex-row gap-2">
                <button class="export btn">
                  <!-- :data="exportFields" -->
                  <export-excel
                    :fetch="exportFields"
                    :meta="json_meta"
                    :name="$t(`titles.subSections`)"
                    :worksheet="$t(`titles.subSections`)"
                  >
                    Export <i class="fal fa-download"></i
                  ></export-excel>
                </button>
              </div>
            </div>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>

      <!-- Start Pagination -->
      <template>
        <div
          class="pagination_container text-center mb-5 d-flex justify-content-end"
        >
          <!-- <div class="select-pagination d-flex">
            <span class="first">{{ $t('show') }}</span>
            <v-select
              :items="[5, 20, 50, 100]"
              v-model="paginations.items_per_page"
            ></v-select>
            <span>{{ $t('entries') }}</span>
          </div> -->

          <v-pagination
            color="primary"
            v-model="paginations.current_page"
            :length="paginations.last_page"
            :total-visible="5"
            @input="fetchData($event)"
          ></v-pagination>
        </div>
      </template>

      <!-- End Pagination -->
    </div>

    <!-- Start Image_Model -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
    <!-- End Image_Model -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.categories.title"),
          disabled: false,
          href: "/categories/show-all",
        },
        {
          text: this.$t("breadcrumb.categories.sub-categories"),
          disabled: false,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItem: null,

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,
      expandData: [],
      expanded: [],

      noExpand: false,
      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 1,
        items_per_page: 20,
      },

      // ========== Loding
      loading: false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),
    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            width: "40",
            sortable: true,
          },
          {
            text: "الصوره",
            align: "center",
            value: "image",

            sortable: true,
          },
          {
            text: "الاسم",
            align: "center",
            value: "label",

            sortable: true,
          },
          {
            text: "ترتيب العرض",
            align: "center",

            value: "ordering",
            sortable: true,
          },
          // {
          //   text: 'عدد المنتجات',
          //   align: 'start',
          //   width: '140',
          //   value: 'products_count',
          //   sortable: true,
          // },
          {
            text: "الوصف",
            align: "center",
            value: "desc",

            sortable: true,
          },
          {
            text: "الاقسام الفرعية",
            align: "center",

            value: "count_of_children",
            sortable: true,
          },
          {
            text: "القسم الرئيسي",
            align: "center",

            value: "parent.label",
            sortable: true,
          },
          {
            text: "عدد المنتجات",
            align: "center",

            value: "count_of_products",
            sortable: true,
          },
          {
            text: "مفعل؟",
            align: "start",

            value: "is_active",
            sortable: true,
          },
          {
            text: "المزيد",
            value: "extendIcon",
            align: "center",
            sortable: false,
          },
          {
            text: "الاجراءات",
            align: "center",
            width: "120",
            value: "actions",
            sortable: true,
          },
        ];
      } else {
        return [
          {
            text: "#",
            align: "center",
            value: "index",
            width: "40",
            sortable: true,
          },
          {
            text: "Image",
            align: "center",
            value: "image",

            sortable: true,
          },
          {
            text: "Name",
            align: "center",
            value: "label",

            sortable: true,
          },
          {
            text: "Order",
            align: "center",

            value: "ordering",
            sortable: true,
          },
          // {
          //   text: 'عدد المنتجات',
          //   align: 'start',
          //   width: '140',
          //   value: 'products_count',
          //   sortable: true,
          // },
          {
            text: "Description",
            align: "center",
            value: "desc",

            sortable: true,
          },
          {
            text: "Sub Category",
            align: "center",

            value: "count_of_children",
            sortable: true,
          },
          {
            text: "Main Category",
            align: "center",

            value: "parent.label",
            sortable: true,
          },
          {
            text: "Products Num",
            align: "center",

            value: "count_of_products",
            sortable: true,
          },
          {
            text: "Active?",
            align: "start",

            value: "is_active",
            sortable: true,
          },
          {
            text: "More",
            value: "extendIcon",
            align: "center",
            sortable: false,
          },
          {
            text: "Control",
            align: "center",
            width: "120",
            value: "actions",
            sortable: true,
          },
        ];
      }
    },
  },

  methods: {
    async exportFields() {
      const data = await this.$axios({
        method: "GET",
        url: "get_all_category",
      });
      let newData = [];
      this.rows.forEach((el) => {
        let newObject = {};
        newObject[`${this.$t("forms.labels.image")}`] = el.image;
        newObject[`${this.$t("forms.labels.name")}`] = el.label;
        newObject[`${this.$t("forms.labels.ordering")}`] = el.ordering;
        newObject[`${this.$t("forms.labels.desc")}`] = el.desc;
        newObject[`${this.$t("forms.labels.SubCategoryNum")}`] =
          el.count_of_children;
        newObject[`${this.$t("forms.labels.mainCategory")}`] =
          el?.parent?.label || this.$t("notFound");
        newObject[`${this.$t("forms.labels.status")}`] = el.is_active
          ? this.$t("active")
          : this.$t("inactive");
        // newObject[`${this.$t("forms.labels.city")}`] = el.city;
        newData.push(newObject);
      });
      return newData;
    },
    // ===== Search Method =====
    filterClick(word) {
      if (!this.loading) {
        this.search = word;
        this.helper_filterSearch();
      }
    },
    mapItem(data) {
      this.expandData = {
        ["نوع مدة التوصيل"]: data.delivery_type_trans,
        // [this.$t("forms.labels.tag")]: data.tag,
        [this.$t("forms.labels.slug")]: data.slug,
      };
    },
    clickRow(rowData) {
      if (this.noExpand) return;
      const indexExpanded = this.expanded.findIndex((i) => i === rowData);
      if (indexExpanded > -1) {
        // Exisit
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded = [];
        this.expanded.push(rowData);
        this.mapItem(rowData);
        // this.expandData = rowData
      }
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: "/categories/add" });
    },
    showItem(item) {
      this.$router.push({ path: "/categories/show/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/categories/edit/" + item.id });
    },

    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.$axios({
        method: "DELETE",
        url: `category/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return item.id != this.itemtoDelete.id;
          });
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    deleteSelected() {
      this.dialogDelete_selected = true;
    },
    deleteSelectedConfirm() {
      this.$axios({
        method: "POST",
        url: `category/deleteAll`,
        data: { ids: this.selected.map((item) => item.id) },
      })
        .then(() => {
          this.rows = this.rows.filter((item) => {
            return !this.selected.filter((obj) => obj.id == item.id).length > 0;
          });
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "bottomRight",
          });
        })
        .catch((err) => {
          this.dialogDelete_selected = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: `category?category_id=${this.$route.params.id}`,
        params: { page: this.paginations.current_page },
      })
        .then((res) => {
          this.statisticsItem = res.data.meta?.total;
          this.paginations.last_page = res.data.meta?.last_page;
          this.paginations.items_per_page = res.data.meta?.per_page;

          this.rows = res.data.data;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fetchData(e) {
      this.$router.replace({ query: { page: e } });
      this.setRows();
    },
  },
  watch: {
    ["$route.params.id"]() {
      this.setRows();
    },
  },

  created() {
    if (this.$route.query.page) {
      this.paginations.current_page = +this.$route.query.page;
    }
    this.setRows();
  },
  // ======= hooks
};
</script>

<style lang="scss" scoped>
.image {
  // width: 120px;
  img {
    width: 100%;
  }
}
</style>
